html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  /* background: #3a3838; */
  background: #001529d1 !important;
  font-family: "Roboto", "Helvetica Neue", Arial, sans-serif;
  color: rgb(63, 60, 60);
  font-family: "Noto Sans", "Open Sans", "Lato,Roboto", Tahoma, system-ui,
    "-apple-system", "BlinkMacSystemFont", "Segoe UI", Helvetica, Arial,
    sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
  -webkit-font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#app {
  height: 100%;
}

.card {
  background-color: #02182d !important;
  border-radius: 5px;
}

.bg-light {
  background-color: #ffffff !important;
}

.card-header {
  border-bottom: unset;
}

.btn-success {
  background-color: #cddc39 !important;
}

.react-numeric-input {
  width: 100%;
}

.rc-menu__item {
  font-size: 12px;
}

.control-header {
  font-size: 12px;
}

.control-menu {
  margin-top: -5px;
}

.control-menu:hover {
  color: #595c5f;
}

.react-resizable-handle {
  color: #595c5f;
}

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.ant-drawer-header {
  border-bottom: 1px solid #7e7b7b;
}

.ant-drawer-title {
  color: rgb(145, 140, 140) !important;
}

.heading {
  color: #a5a6a7 !important;
}

.datasource-list {
  overflow: auto;
}

.datasource-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(195, 192, 201, 0.3);
  background-color: #a5a6a7;
}

.datasource-list::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  background-color: #a5a6a7;
}

.datasource-list::-webkit-scrollbar-thumb {
  background-color: #d8d6d6;
  border-radius: 3px;
}

.control-unit::after {
  content: ")";
}

.control-unit::before {
  content: "(";
}

.ant-notification-notice-message-single-line-auto-margin,
.ant-notification-notice-message {
  color: #fff !important;
}

.anticon-close {
  color: #fff !important;
}

.wrapper {
  height: 100%;
  position: relative;
}
.graphContainer {
  height: 100%;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 0;
  background-color: red !important;
}

.secondGraph {
  height: 100%;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 4;
  background-color: violet !important;
}

.r104f42s700-canary49 {
  color: #ffff;
  --background-color: #001529;
  --header-background-color: #13273afa;
  border-radius: 20px;
  --row-hover-background-color: unset;
  --row-selected-background-color: unset;
  --row-selected-hover-background-color: unset;
  --selection-color: unset;
  --border-color: #001529;
}

.react-resizable-handle {
  background-image: none;
}

.main-hawfinch-grid {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.ant-layout,
.ant-layout-footer {
  background-color: unset;
}

.ant-layout-footer,
.ant-modal-title {
  color: #fff;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #001529 !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub,
.ant-modal-header {
  background: #001529 !important;
}

.form-select,
.form-control,
.ant-layout-sider-trigger,
.ant-modal-content {
  background-color: #011d38;
  border-color: #011b33;
  color: #e0e0e0;
}

.ant-modal-content {
  color: #000 !important;
}

@font-face {
  font-family: hawfinchFont;
  src: url("PlantagenetCherokee.ttf");
}

.company-name {
  font-size: 50px;
  font-family: "hawfinchFont";
  color: #ffa00094;
}

.company-tag-line {
  font-size: 4em;
}
